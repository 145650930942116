<template>
  <div id="retirement">
    <el-dialog
      :title="retirementFormTitle"
      width="1500px"
      :visible.sync="retirementDialogVisible"
      :close-on-click-modal="false"
      @close="retirementDialogClose"
    >
      <el-form
        ref="retirementFormRef"
        :model="retirementForm"
        :rules="retirementFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-form-item>
          <vxe-toolbar
            v-if="retirementFormTitle === '新增报废单'
              || retirementFormTitle === '修改报废单'"
          >
            <template #buttons>
              <vxe-button @click="insertRowEvent()">
                新增
              </vxe-button>
            </template>
          </vxe-toolbar>
          <vxe-table
            ref="xTable"
            border
            resizable
            show-overflow
            keep-source
            :data="retirementDetailList"
            :edit-config="{trigger: 'click', mode: 'cell'}"
          >
            <vxe-table-column
              field="assetNo"
              title="资产编号"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="assetName"
              title="资产名称"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="spec"
              title="型号规格"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="useDept"
              title="使用部门"
              :edit-render="{
                name: '$select',
                props: {clearable: true},
                options: [
                  { value: '质量部', label: '质量部' },
                  { value: '技术部', label: '技术部' },
                  { value: '生产部', label: '生产部' },
                  { value: '销售部', label: '销售部' },
                  { value: '物控部', label: '物控部' },
                  { value: '管理部', label: '管理部' },
                  { value: '财务部', label: '财务部' }
                ]
              }"
            />
            <vxe-table-column
              field="placement"
              title="安放地点"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="scrapReason"
              title="报废原因"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="remarks"
              title="备注（请写明损坏原因、损坏部位）"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              v-if="retirementFormTitle === '新增报废单'
                || retirementFormTitle === '修改报废单'"
              title="操作"
              width="100"
            >
              <template #default="{ row }">
                <template>
                  <vxe-button @click="removeRowEvent(row)">
                    删除
                  </vxe-button>
                </template>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-form-item>
        <el-row>
          <el-col :span="6">
            <el-form-item label="申请人" prop="applicant">
              <el-input
                v-model="retirementForm.applicant"
                clearable
                :disabled="retirementFormTitle !== '新增报废单'
                  && retirementFormTitle !== '修改报废单'
                  && retirementFormTitle !== '报废单详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="申请日期" prop="applicationDate">
              <el-date-picker
                v-model="retirementForm.applicationDate"
                value-format="yyyy-MM-dd"
                :disabled="retirementFormTitle !== '新增报废单'
                  && retirementFormTitle !== '修改报废单'
                  && retirementFormTitle !== '报废单详情'"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="使用部门说明" prop="useDeptDescription">
              <el-input
                v-model="retirementForm.useDeptDescription"
                type="textarea"
                :rows="4"
                :disabled="retirementFormTitle !== '使用部门'
                  && retirementFormTitle !== '报废单详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="处置方式说明" prop="disposalDescription">
              <el-input
                v-model="retirementForm.disposalDescription"
                type="textarea"
                :rows="4"
                :disabled="retirementFormTitle !== '管代'
                  && retirementFormTitle !== '报废单详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="财务部" prop="financeDeptSuggest">
              <el-input
                v-model="retirementForm.financeDeptSuggest"
                type="textarea"
                :rows="4"
                :disabled="retirementFormTitle !== '财务'
                  && retirementFormTitle !== '报废单详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="批示" prop="instructions">
              <el-input
                v-model="retirementForm.instructions"
                type="textarea"
                :rows="4"
                :disabled="retirementFormTitle !== '总经理'
                  && retirementFormTitle !== '报废单详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="签名" prop="useDeptSignature">
              <el-input
                v-model="retirementForm.useDeptSignature"
                clearable
                :disabled="retirementFormTitle !== '使用部门'
                  && retirementFormTitle !== '报废单详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="管理者代表" prop="managerSignature">
              <el-input
                v-model="retirementForm.managerSignature"
                clearable
                :disabled="retirementFormTitle !== '管代'
                  && retirementFormTitle !== '报废单详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="负责人" prop="financialManagerSignature">
              <el-input
                v-model="retirementForm.financialManagerSignature"
                clearable
                :disabled="retirementFormTitle !== '财务'
                  && retirementFormTitle !== '报废单详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="总经理" prop="generalManagerSignature">
              <el-input
                v-model="retirementForm.generalManagerSignature"
                clearable
                :disabled="retirementFormTitle !== '总经理'
                  && retirementFormTitle !== '报废单详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="日期" prop="useDeptTime">
              <el-date-picker
                v-model="retirementForm.useDeptTime"
                value-format="yyyy-MM-dd"
                :disabled="retirementFormTitle !== '使用部门'
                  && retirementFormTitle !== '报废单详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="日期" prop="managerTime">
              <el-date-picker
                v-model="retirementForm.managerTime"
                value-format="yyyy-MM-dd"
                :disabled="retirementFormTitle !== '管代'
                  && retirementFormTitle !== '报废单详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="日期" prop="financialManagerTime">
              <el-date-picker
                v-model="retirementForm.financialManagerTime"
                value-format="yyyy-MM-dd"
                :disabled="retirementFormTitle !== '财务'
                  && retirementFormTitle !== '报废单详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="日期" prop="generalManagerTime">
              <el-date-picker
                v-model="retirementForm.generalManagerTime"
                value-format="yyyy-MM-dd"
                :disabled="retirementFormTitle !== '总经理'
                  && retirementFormTitle !== '报废单详情'"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="retirementDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="retirementFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="申请人">
        <el-input v-model="searchForm.applicant" placeholder="请输入申请人" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="retirementPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="applicant" label="申请人" />
      <el-table-column label="申请日期">
        <template slot-scope="scope">
          <span v-if="scope.row.applicationDate">{{ scope.row.applicationDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="useDeptDescription" label="使用部门说明" />
      <el-table-column prop="disposalDescription" label="处置方式说明" />
      <el-table-column prop="financeDeptSuggest" label="财务部" />
      <el-table-column prop="instructions" label="批示" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdateByUseDept(scope.$index, scope.row)"
          >
            使用部门
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdateByManager(scope.$index, scope.row)"
          >
            管代
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdateByFinanceDept(scope.$index, scope.row)"
          >
            财务
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdateByGeneralManager(scope.$index, scope.row)"
          >
            总经理
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="retirementPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addRetirement,
  deleteRetirement,
  updateRetirement,
  updateRetirementByUseDept,
  updateRetirementByManager,
  updateRetirementByFinanceDept,
  updateRetirementByGeneralManager,
  selectRetirementInfo,
  selectRetirementList
} from '@/api/device/retirement'

export default {
  data () {
    return {
      retirementDialogVisible: false,
      retirementFormTitle: '',
      retirementForm: {
        id: '',
        retirementDetailJson: '',
        applicant: '',
        applicationDate: '',
        useDeptDescription: '',
        useDeptSignature: '',
        useDeptTime: '',
        disposalDescription: '',
        managerSignature: '',
        managerTime: '',
        financeDeptSuggest: '',
        financialManagerSignature: '',
        financialManagerTime: '',
        instructions: '',
        generalManagerSignature: '',
        generalManagerTime: ''
      },
      retirementFormRules: {
        deviceId: [{ required: true, message: '设备不能为空', trigger: ['blur', 'change']}]
      },
      retirementPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        applicant: ''
      },
      retirementDetailList: []
    }
  },
  created () {
    selectRetirementList(this.searchForm).then(res => {
      this.retirementPage = res
    })
  },
  methods: {
    retirementDialogClose () {
      this.$refs.retirementFormRef.resetFields()
      this.retirementDetailList = []
    },
    retirementFormSubmit () {
      if (this.retirementFormTitle === '报废单详情') {
        this.retirementDialogVisible = false
        return
      }
      this.$refs.retirementFormRef.validate(async valid => {
        if (valid) {
          if (this.retirementFormTitle === '新增报废单') {
            this.retirementForm.retirementDetailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
            await addRetirement(this.retirementForm)
          } else if (this.retirementFormTitle === '修改报废单') {
            this.retirementForm.retirementDetailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
            await updateRetirement(this.retirementForm)
          } else if (this.retirementFormTitle === '使用部门') {
            await updateRetirementByUseDept(this.retirementForm)
          } else if (this.retirementFormTitle === '管代') {
            await updateRetirementByManager(this.retirementForm)
          } else if (this.retirementFormTitle === '财务') {
            await updateRetirementByFinanceDept(this.retirementForm)
          } else if (this.retirementFormTitle === '总经理') {
            await updateRetirementByGeneralManager(this.retirementForm)
          }
          this.retirementPage = await selectRetirementList(this.searchForm)
          this.retirementDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.retirementFormTitle = '新增报废单'
      this.retirementDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteRetirement(row.id)
        if (this.retirementPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.retirementPage = await selectRetirementList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.retirementFormTitle = '修改报废单'
      this.retirementDialogVisible = true
      this.selectRetirementInfoById(row.id)
    },
    handleUpdateByUseDept (index, row) {
      this.retirementFormTitle = '使用部门'
      this.retirementDialogVisible = true
      this.selectRetirementInfoById(row.id)
    },
    handleUpdateByManager (index, row) {
      this.retirementFormTitle = '管代'
      this.retirementDialogVisible = true
      this.selectRetirementInfoById(row.id)
    },
    handleUpdateByFinanceDept (index, row) {
      this.retirementFormTitle = '财务'
      this.retirementDialogVisible = true
      this.selectRetirementInfoById(row.id)
    },
    handleUpdateByGeneralManager (index, row) {
      this.retirementFormTitle = '总经理'
      this.retirementDialogVisible = true
      this.selectRetirementInfoById(row.id)
    },
    handleInfo (index, row) {
      this.retirementFormTitle = '报废单详情'
      this.retirementDialogVisible = true
      this.selectRetirementInfoById(row.id)
    },
    selectRetirementInfoById (id) {
      selectRetirementInfo(id).then(res => {
        this.retirementForm.id = res.id
        this.retirementForm.applicant = res.applicant
        this.retirementForm.applicationDate = res.applicationDate
        this.retirementForm.useDeptDescription = res.useDeptDescription
        this.retirementForm.useDeptSignature = res.useDeptSignature
        this.retirementForm.useDeptTime = res.useDeptTime
        this.retirementForm.disposalDescription = res.disposalDescription
        this.retirementForm.managerSignature = res.managerSignature
        this.retirementForm.managerTime = res.managerTime
        this.retirementForm.financeDeptSuggest = res.financeDeptSuggest
        this.retirementForm.financialManagerSignature = res.financialManagerSignature
        this.retirementForm.financialManagerTime = res.financialManagerTime
        this.retirementForm.instructions = res.instructions
        this.retirementForm.generalManagerSignature = res.generalManagerSignature
        this.retirementForm.generalManagerTime = res.generalManagerTime
        this.retirementDetailList = res.detailList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectRetirementList(this.searchForm).then(res => {
        this.retirementPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectRetirementList(this.searchForm).then(res => {
        this.retirementPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectRetirementList(this.searchForm).then(res => {
        this.retirementPage = res
      })
    },
    async insertRowEvent (row) {
      let { row: newRow } = await this.$refs.xTable.insertAt({}, -1)
      await this.$refs.xTable.setActiveRow(newRow)
    },
    removeRowEvent (row) {
      this.$refs.xTable.remove(row)
    }
  }
}
</script>

<style>
</style>
