import axios from '@/common/axios'
import qs from 'qs'

export function addRetirement (data) {
  return axios({
    method: 'post',
    url: '/device/retirement/add',
    data: qs.stringify(data)
  })
}

export function deleteRetirement (id) {
  return axios({
    method: 'delete',
    url: '/device/retirement/delete/' + id
  })
}

export function updateRetirement (data) {
  return axios({
    method: 'put',
    url: '/device/retirement/update',
    data: qs.stringify(data)
  })
}

export function updateRetirementByUseDept (data) {
  return axios({
    method: 'put',
    url: '/device/retirement/useDept',
    data: qs.stringify(data)
  })
}

export function updateRetirementByManager (data) {
  return axios({
    method: 'put',
    url: '/device/retirement/manager',
    data: qs.stringify(data)
  })
}

export function updateRetirementByFinanceDept (data) {
  return axios({
    method: 'put',
    url: '/device/retirement/financeDept',
    data: qs.stringify(data)
  })
}

export function updateRetirementByGeneralManager (data) {
  return axios({
    method: 'put',
    url: '/device/retirement/generalManager',
    data: qs.stringify(data)
  })
}

export function selectRetirementInfo (id) {
  return axios({
    method: 'get',
    url: '/device/retirement/info/' + id
  })
}

export function selectRetirementList (query) {
  return axios({
    method: 'get',
    url: '/device/retirement/list',
    params: query
  })
}
